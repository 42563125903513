//mapViewList.js
import { Link } from 'react-router-dom';
import useFetchAllProperties from '../../hooks/useFetchAllProperties';

export const MapViewList = () => {
    const { properties } = useFetchAllProperties();
    return (
        <>
            {properties.map(property => (
                <Link to={`/record/${property.id}`} className="list-group-item list-group-item-action p-3 rounded border my-1" key={property.id}>
                    <div className="py-2">
                        <h5 className="mb-0 cond-id">ID: {property.id}</h5>
                    </div>
                    <div className="py-2">
                        <h6 className="mb-0">Park Name:</h6>
                        <p className="mb-0 opacity-75">
                            {property.recordView?.parkName || property.recordview?.parkName}
                        </p>
                    </div>
                    <div className="py-2">
                        <h6 className="mb-0">Last Inspection Date:</h6>
                        <p className="mb-0 opacity-75">
                            {property.recordView?.inspectDate || property.recordview?.inspectDate}
                        </p>
                    </div>
                </Link>
            ))}
        </>
    )
}


export default MapViewList

import React, { useState } from 'react';
import data from '../../data.json';


const ScoringTab = () => {

    const handleSelectChange = (e) => {
        // You can access the new value with e.target.value
        console.log(e.target.value);
    };

    const [checkboxState, setCheckboxState] = useState(false); // initialize state

    const handleCheckboxChange = () => {
        setCheckboxState(); // flip the boolean state when checkbox is clicked
    }



    return (
        <>
            {data ? (


                <div className="tab-pane fade" id="nav-scoring" role="tabpanel" aria-labelledby="nav-scoring-tab">
                    <div className="container">

                        <nav className="score-nav-bar shadow">
                            <div className="nav nav-pills" id="nav-score-struct-select" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#score-struct-01" type="button" role="tab" aria-controls="score-struct-01" aria-selected="true">Retaining Wall</button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#score-struct-02" type="button" role="tab" aria-controls="score-struct-02" aria-selected="false">Synthetic Turf</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#score-struct-03" type="button" role="tab" aria-controls="score-struct-03" aria-selected="false">Building</button>
                            </div>
                        </nav>


                        <div className="tab-content my-4" id="nav-score-struct-Content">
                            <div className="tab-pane score-struct-pane fade show active" id="score-struct-01" role="tabpanel" aria-labelledby="score-struct-01-tab">
                                <div className="score-assessment-wrap">
                                    <div className="col-4">
                                        <nav>
                                            <select onChange={handleSelectChange} className="form-select" id="nav-score-assess-select" role="tablist">
                                                <option defaultValue="#score-assess-01">A. Geotechnical</option>
                                                <option value="#score-assess-02">B. Structural</option>
                                                <option value="#score-assess-03">C. Material</option>
                                                <option value="#score-assess-04">D. Enviroment </option>
                                                <option value="#score-assess-05">E. Wall Protection</option>
                                                <option value="#score-assess-06">F. Functional Accessories</option>
                                            </select>
                                        </nav>
                                    </div>
                                    <div className="tab-content my-4 p-5 border shadow rounded" id="nav-score-assessContent">

                                        <div className="tab-pane score-assess-pane fade show active" id="score-assess-01" role="tabpanel" aria-labelledby="score-assess-01-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>A01. Rotation</h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="a01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="a01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="a01-inward">Inward</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="a01-outward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="a01-outward">Outward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="a01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img1" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img2" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img3" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>A02. Displacement</h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="a02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="a02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>A03. Settlement</h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="a03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="a03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="a03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="a03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-pane score-assess-pane fade" id="score-assess-02" role="tabpanel" aria-labelledby="score-assess-02-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>B01. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="b01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="b01-inward" defaultValue="b01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="b01-inward">Inward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="b01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>B02. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="b02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="b02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>B03. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="b03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="b03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="b03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="b03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-pane score-assess-pane fade" id="score-assess-03" role="tabpanel" aria-labelledby="score-assess-03-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>C01. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="c01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="c01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="c01-inward">Inward</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="c01-outward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="c01-outward">Outward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="c01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>C02. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="c02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="c02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>C03. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="c03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="c03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="c03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="c03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-pane score-assess-pane fade" id="score-assess-04" role="tabpanel" aria-labelledby="score-assess-04-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>D01. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="d01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="d01-inward" defaultValue="d01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="d01-inward">Inward</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="d01-outward" defaultValue="d01-outward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="d01-outward">Outward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="d01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>D02. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="d02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="d02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>D03. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="d03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="d03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="d03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="d03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-pane score-assess-pane fade" id="score-assess-05" role="tabpanel" aria-labelledby="score-assess-05-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>E01. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="e01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="e01-inward" defaultValue="e01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="e01-inward">Inward</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="e01-outward" defaultValue="e01-outward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="e01-outward">Outward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="e01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>E02. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="e02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="e02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>E03. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="e03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="e03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="e03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="e03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-pane score-assess-pane fade" id="score-assess-06" role="tabpanel" aria-labelledby="score-assess-06-tab">
                                            <ul className="list-group list-group-flush score-list-group">
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>F01. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="f01-notes" className="form-label">Notes:</label>
                                                                <div className="d-inline-flex ms-5 justify-content-end">
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="f01-inward" defaultValue="f01-inward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="f01-inward">Inward</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline" readOnly>
                                                                        <input className="form-check-input" type="checkbox" id="f01-outward" defaultValue="f01-outward" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                        <label className="form-check-label" htmlFor="f01-outward">Outward</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="f01-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f01-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f01-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f01-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f01-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f01-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f01-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f01-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f01-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f01-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f01-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>F02. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="f02-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="f02-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f02-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f02-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f02-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f02-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f02-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f02-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f02-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f02-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f02-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f02-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item py-4">
                                                    <div className="">
                                                        <h5>F03. </h5>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-8">
                                                                <label htmlFor="f03-notes" className="form-label">Notes:</label>
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="col-12 col-md-4 score-notes">

                                                                <textarea className="form-control" id="f03-notes" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-4 col-md-2 score-img">
                                                                <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                                            </div>
                                                            <div className="col-12 col-md-2 score-checks">
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f03-inaccesible" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f03-inaccesible">Inaccesible</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f03-none" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f03-none">None</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f03-minor" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f03-minor">Minor</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f03-moderate" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f03-moderate">Moderate</label>
                                                                </div>
                                                                <div className="condition_check form-check mt-n2 ">
                                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="f03-severe" checked={checkboxState} onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="f03-severe">Severe</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                </div>




                            </div>
                            <div className="tab-pane score-struct-pane fade" id="score-struct-02" role="tabpanel" aria-labelledby="score-struct-02-tab">
                                <div className="">
                                    <div className="col-4 pb-4">
                                        <nav>
                                            <select onChange={handleSelectChange} className="form-select" id="nav-synth-select" role="tablist">
                                                <option defaultValue="#synth-01" >GSA: 01 - Material Support</option>
                                                <option value="#synth-02">GSA: 02 - Missing Sections</option>
                                                <option value="#synth-03">GSA: 03 - Opening / Seperation</option>
                                                <option value="#synth-04">GSA: 04 - Unevenness</option>
                                                <option value="#synth-05">GSA: 05 - Turf Wrinkle</option>
                                            </select>
                                        </nav>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <form className="row gx-3 gy-2 align-items-center pb-4">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="KeyMap">KeyMap</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">KeyMap</div>
                                                        <div className="record-form-img-wrap form-control" id="KeyMap">
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="me-3 position-relative">
                                                                        <img src="https://via.placeholder.com/600x400" style={{ maxHeight: "160px" }} alt="score-img4" />
                                                                        <button type="button" className="position-absolute recordImgModalBtn" data-bs-toggle="modal" data-bs-target="#recordImgModal" style={{ width: "100%", height: "100%", left: "0", top: "0" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16" style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                                                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path>
                                                                            </svg>
                                                                        </button>
                                                                        <div className="modal fade recordImgModal" id="recordImgModal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "none" }} aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-body">
                                                                                        <button type="button" className="btn-close recordImgModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        <img id="modalImg img-fluid rounded" src="https://via.placeholder.com/600x400" alt="score-img5" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className="btn-group p-3">
                                                                    <select onChange={handleSelectChange} className="form-select" id="record-form-img-select" role="tablist">
                                                                        <option value="#record-form-img-02">Bitmap</option>
                                                                        <option value="#record-form-img-03">DWG</option>
                                                                        <option value="#record-form-img-04">PDF</option>
                                                                        <option value="#record-form-img-05">ShapeFile</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>


                                        </div>
                                        <div className="col-md-7">
                                            <form className="row gx-3 gy-2 align-items-center pb-4">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-GSA">GSA</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">GSA</div>
                                                        <input type="text" className="form-control" id="synth-GSA" defaultValue="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-installY">Install Year</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Install Year</div>
                                                        <input type="text" className="form-control" id="synth-installY" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-severity">Severity</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Severity</div>
                                                        <input type="text" className="form-control" id="synth-severity" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-extent">Extent</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Extent</div>
                                                        <input type="text" className="form-control" id="synth-extent" />
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form className="row gx-3 gy-2 align-items-center">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-desc">Description</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Description</div>
                                                        <input type="text" className="form-control" id="synth-desc" placeholder="Problem description?" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-def">Deficiency Type</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Deficiency Type</div>
                                                        <input type="text" className="form-control" id="synth-def" placeholder="Problem description type?" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-mat">Material Type</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Material Type</div>
                                                        <input type="text" className="form-control" id="synth-mat" placeholder="Material" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <label className="visually-hidden" htmlFor="synth-quan">Quantity</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Quantity</div>
                                                        <input type="text" className="form-control" id="synth-quan" placeholder="###" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="visually-hidden" htmlFor="synth-units">Units</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Units</div>
                                                        <input type="text" className="form-control" id="synth-units" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-pot">Potential Action</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Potential Action</div>
                                                        <input type="text" className="form-control" id="synth-pot" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="synth-notes">Notes</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Notes</div>
                                                        <textarea type="text" className="form-control" id="synth-notes" placeholder=""></textarea>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane score-struct-pane fade" id="score-struct-03" role="tabpanel" aria-labelledby="score-struct-03-tab">
                                <div className="">
                                    <div className="col-4 pb-4">
                                        <nav>
                                            <select onChange={handleSelectChange} className="form-select" id="nav-build-select" role="tablist">
                                                <option defaultValue="#build-01">GSA: 01 - Material Support</option>
                                                <option value="#build-02">GSA: 02 - Missing Sections</option>
                                                <option value="#build-03">GSA: 03 - Opening / Seperation</option>
                                                <option value="#build-04">GSA: 04 - Unevenness</option>
                                                <option value="#build-05">GSA: 05 - Turf Wrinkle</option>
                                            </select>
                                        </nav>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <form className="row gx-3 gy-2 align-items-center pb-4">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="KeyMap">KeyMap</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">KeyMap</div>
                                                        <div className="record-form-img-wrap form-control" id="KeyMap">
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="me-3 position-relative">
                                                                        <img src="https://via.placeholder.com/600x400" style={{ maxHeight: "160px" }} alt="score-img" />
                                                                        <button type="button" className="position-absolute recordImgModalBtn" data-bs-toggle="modal" data-bs-target="#recordImgModal2" style={{ width: "100%", height: "100%", left: "0", top: "0" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16" style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                                                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path>
                                                                            </svg>
                                                                        </button>
                                                                        <div className="modal fade recordImgModal" id="recordImgModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "none" }} aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-body">
                                                                                        <button type="button" className="btn-close recordImgModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        <img id="modalImg img-fluid rounded" src="https://via.placeholder.com/600x400" alt="score-img6" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className="btn-group p-3">
                                                                    <select onChange={handleSelectChange} className="form-select" id="record-form-img-select" role="tablist">
                                                                        <option value="#record-form-img-02">Bitmap</option>
                                                                        <option value="#record-form-img-03">DWG</option>
                                                                        <option value="#record-form-img-04">PDF</option>
                                                                        <option value="#record-form-img-05">ShapeFile</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>


                                        </div>
                                        <div className="col-md-7">
                                            <form className="row gx-3 gy-2 align-items-center pb-4">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-GSA">GSA</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">GSA</div>
                                                        <input type="text" className="form-control" id="build-GSA" defaultValue="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-installY">Install Year</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Install Year</div>
                                                        <input type="text" className="form-control" id="build-installY" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-severity">Severity</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Severity</div>
                                                        <input type="text" className="form-control" id="build-severity" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-extent">Extent</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Extent</div>
                                                        <input type="text" className="form-control" id="build-extent" />
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form className="row gx-3 gy-2 align-items-center">

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-desc">Description</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Description</div>
                                                        <input type="text" className="form-control" id="build-desc" placeholder="Problem description?" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-def">Deficiency Type</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Deficiency Type</div>
                                                        <input type="text" className="form-control" id="build-def" placeholder="Problem description type?" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-mat">Material Type</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Material Type</div>
                                                        <input type="text" className="form-control" id="build-mat" placeholder="Material" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <label className="visually-hidden" htmlFor="build-quan">Quantity</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Quantity</div>
                                                        <input type="text" className="form-control" id="build-quan" placeholder="###" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="visually-hidden" htmlFor="build-units">Units</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Units</div>
                                                        <input type="text" className="form-control" id="build-units" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-pot">Potential Action</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Potential Action</div>
                                                        <input type="text" className="form-control" id="build-pot" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <label className="visually-hidden" htmlFor="build-notes">Notes</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text">Notes</div>
                                                        <textarea type="text" className="form-control" id="build-notes" placeholder=""></textarea>
                                                    </div>
                                                </div>

                                            </form>
                                        </div><div className="col-3 col-md-2 score-img">
                                            <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                        </div><div className="col-3 col-md-2 score-img">
                                            <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                        </div><div className="col-3 col-md-2 score-img">
                                            <img src="https://via.placeholder.com/350" className="img-thumbnail" alt="score-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>


                </div>



            ) : (
                <>tste</>
            )}
        </>
    )
}

export default ScoringTab
import { Link } from 'react-router-dom';

function Dashboard() {

    return (

        <>

            <div className="container py-5">
                <div className="row p-2">
                    <div className="col-lg-12 p-5">
                        <h1> <i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard</h1>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link to="/mapview">
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-map fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">Search Map View</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link className="text-decoration-none" to="/searchrecord">
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-search fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">Search Record View</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link className="text-decoration-none" to="/addnewsite">
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-plus-square fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">New Site Data Entry</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link className="text-decoration-none" to="/reports">
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-area-chart fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">Reports</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link className="text-decoration-none" to="/pagenotprovided">
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-clock fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">Pending Review</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xs-6 col-sm-4 col-xl-2 p-2">
                        <Link className="text-decoration-none" to="/pagenotprovided" >
                            <div className="cna-card card p-3 shadow parks-green-back text-center border-0 h-100">
                                <div className="card-body d-flex flex-column">
                                    <i className="fa fa-question fa-2x"></i>
                                    <hr />
                                    <p className="card-title lead mb-auto">Primavera Unifier</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

            </div>

        </>


    );
}

export default Dashboard;
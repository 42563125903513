import React from 'react';
import ChartComponent from './chartComponent';

const CumulativeCostChart = () => {
    const cumulativeCostChart = {
        credits: {
            enabled: false,
        },
        title: {
            text: 'Cumulative Cost',
        },

        subtitle: {
            text: '',
        },

        xAxis: {
            categories: [
                'M843-SYNTURF-0043', 'M272-SYNTURF-0018', 'M539-SYNTURF-0012', 'M707-SYNTURF-0024', 'M167-SYNTURF-0025', 'M286-SYNTURF-0045', 'M088-SYNTURF-0019', 'M018-SYNTURF-0002', 'M070-SYNTURF-0003', '310-SYNTURF-0004', 'M583-SYNTURF-0014', 'M144-SYNTURF-0043', 'M147-SYNTURF-0026', 'M843-SYNTURF-0043', 'M272-SYNTURF-0018', 'M539-SYNTURF-0012', 'M707-SYNTURF-0024', 'M167-SYNTURF-0025', 'M286-SYNTURF-0045', 'M088-SYNTURF-0019', 'M018-SYNTURF-0002', 'M070-SYNTURF-0003', '310-SYNTURF-0004', 'M583-SYNTURF-0014',
            ]
        },

        yAxis: {
            title: {
                text: 'Millions'
            }
        },
        tooltip: {
            valueSuffix: ' Million'
        },
        plotOptions: {
            series: {
                borderRadius: '10%'
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            type: 'column',
            name: '2020',
            data: [1, 2, 3, 4, 4, 5, 6, 7, 8, 8, 9, 10, 15, 20, 21, 22, 22, 23, 23, 24, 25, 26, 27, 28,],
            color: '#59A7C6',
        }, {
            type: 'line',
            data: [26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45],
            color: '#C65969',
            lineWidth: 5,
            marker: { enabled: false }
        }]
    };

    return (
        <>
            <ChartComponent options={cumulativeCostChart} />
        </>
    );
}

export default CumulativeCostChart;

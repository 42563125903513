import React from 'react';
import { Link } from 'react-router-dom';

const FourOfour = () => {
  return (
    <div className="main-page logoBack">
      <h1>404</h1>
      <nav>
        <Link to="/main">Home</Link>
      </nav>
    </div>
  );
}

export default FourOfour;
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Replace these values with your own config values from Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyCucyVoLxizGvjDtgKyWMJID_XW4g-xQD8",
  authDomain: "parks-demo-data.firebaseapp.com",
  databaseURL: "https://parks-demo-data-default-rtdb.firebaseio.com",
  projectId: "parks-demo-data",
  storageBucket: "parks-demo-data.appspot.com",
  messagingSenderId: "375886299325",
  appId: "1:375886299325:web:1703d4f901b8ddf329f7d4",
  measurementId: "G-9P1HVJNMEJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
import { useState } from 'react';


const SafetyFlagTab = ({ data, onChange, }) => {

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const handleImageClick = (img) => {
        setModalImage(img);
        setShowModal(true);
    };
    console.log(data)
    return (
        <>
            {data ? (

                <div className="tab-pane fade" id="nav-safety" role="tabpanel" aria-labelledby="nav-safety-tab">


                    <div className="container text-center py-5">
                        <div className="row d-flex justify-content-center">
                            {data.imgs && Object.entries(data.imgs).map(([key, img]) => (
                                <div key={key} className="col-md-2 position-relative" style={{ cursor: "pointer", }}>
                                    <img src={img} alt="sately img" className="img-thumbnail" onClick={() => handleImageClick(img)} />
                                    <div style={{ position: "relative", cursor: "pointer", }}>
                                        <button type="button" className="position-absolute recordImgModalBtn" data-bs-toggle="modal" data-bs-target="#recordImgModal" style={{ width: "100%", height: "100%", left: "0", top: "0" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16" style={{ position: "absolute", bottom: "13px", right: "10px" }}>
                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path>
                                            </svg>
                                        </button>
                                    </div>

                                </div>

                            ))}


                        </div>
                    </div>

                    <div className={showModal ? 'modal fade recordImgModal show d-block safetyImgs ' : 'modal fade recordImgModal'} tabIndex="-1">
                        <div className="modalBackdrop"></div>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="btn-close recordImgModalClose" onClick={() => setShowModal(false)}></button>
                                    <img src={modalImage} alt="sately img" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>





                    <form className="row gx-3 gy-2 align-items-center pb-4 safety-form">
                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="conditions">Conditions</label>
                            <div className="input-group">
                                <div className="input-group-text">Conditions</div>
                                <textarea type="text" className="form-control auto-resize" id="conditions" placeholder="" defaultValue={data.conditions || ""} onChange={onChange}></textarea>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="hazzard">Hazzard</label>
                            <div className="input-group">
                                <div className="input-group-text">Hazzard</div>
                                <textarea type="text" className="form-control auto-resize" id="hazzard" placeholder="" defaultValue={data.hazzard || ""} onChange={onChange}></textarea>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="recommendImmediateAction">Recommendation for immediate action</label>
                            <div className="input-group">
                                <div className="input-group-text">Recommendation for immediate action</div>
                                <textarea type="text" className="form-control auto-resize" id="recommendImmediateAction" placeholder="" defaultValue={data.recommendImmediateAction || ""} onChange={onChange}></textarea>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="recommendFollowUpAction">Recommendation for follow-up action</label>
                            <div className="input-group">
                                <div className="input-group-text">Recommendation for follow-up action</div>
                                <textarea type="text" className="form-control auto-resize" id="recommendFollowUpAction" placeholder="" defaultValue={data.recommendFollowUpAction || ""} onChange={onChange}></textarea>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="location">Location</label>
                            <div className="input-group">
                                <div className="input-group-text">Location</div>
                                <textarea type="text" className="form-control auto-resize" id="location" placeholder="" defaultValue={data.location || ""} onChange={onChange}></textarea>
                            </div>
                        </div>
                    </form>



                </div>


            ) : (
                <></>
            )}
        </>
    )
}

export default SafetyFlagTab
import { Link } from 'react-router-dom';

const PageNotProvided = () => {
    return (
        <div className="container" style={{ marginTop: "150px" }}>
            <div className="row text-center">
                <div className="col">
                    <h2>This page was not provided in the mockup</h2>
                    <Link to="/dashboard" className="btn btn-primary" style={{ maxWidth: "190px" }}>Back to Dashboard</Link>
                </div>
            </div>
        </div>


    )
}

export default PageNotProvided
// useFetchAllProperties.js
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import db from '../components/firebase'; // adjust the path to your firebase config

const useFetchAllProperties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const propertiesCollection = collection(db, 'properties');
                const propertySnapshot = await getDocs(propertiesCollection);
                const propertyList = propertySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));
                setProperties(propertyList);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching properties:", err);
                setError(err);
                setLoading(false);
            }
        };

        fetchProperties();
    }, []);
    return { properties, loading, error };
};

export default useFetchAllProperties;

//commentTab.js


const CommentTab = ({ data, onChange, }) => {


    return (
        <>
            {data ? (

                <div className="tab-pane fade" id="nav-comments" role="tabpanel" aria-labelledby="nav-comments-tab">
                    <form className="row gx-3 gy-2 align-items-center pb-4 comments-form">
                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="summary">Summary</label>
                            <div className="input-group">
                                <div className="input-group-text">Summary</div>
                                <textarea type="text" className="form-control auto-resize" id="summary" placeholder="" defaultValue={data.summary || ""} onChange={onChange}></textarea>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="noteAnyPreviousRepair">Note Any Previous Repairs</label>
                            <div className="input-group">
                                <div className="input-group-text">Note Any Previous Repairs</div>
                                <textarea type="text" className="form-control auto-resize" id="noteAnyPreviousRepair" placeholder="" defaultValue={data.noteAnyPreviousRepair || ""} onChange={onChange}></textarea>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="visually-hidden" htmlFor="fieldSketch">Field Sketch</label>
                            <div className="input-group">
                                <div className="input-group-text">Field Sketch</div>
                                <textarea type="text" className="form-control auto-resize" id="fieldSketch" placeholder="" defaultValue={data.fieldSketch || ""} onChange={onChange}></textarea>
                            </div>
                        </div>
                    </form>


                </div>


            ) : (
                <></>
            )}
        </>
    )
}

export default CommentTab
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import useFetchProperty from '../../hooks/useFetchProperty';
import { setDoc, doc } from 'firebase/firestore';
import db from '../firebase';
import RecordViewTab from './recordViewTab';
import CommentTab from './commentTab';
import ChecklistTab from './checklistTab';
import ScoringTab from './scoringTab';
import SafetyFlagTab from './safetyFlagTab';

function PropertyDetails() {
    const { id } = useParams();
    const { property } = useFetchProperty(id);
    const [showModal, setShowModal] = useState(false);

    // Initiate with default empty values
    const [formData, setFormData] = useState({
        recordview: {},
        comments: {},
        safetyFlags: {},
    });

    useEffect(() => {
        if (property) {
            setFormData({
                recordview: property.recordview || {},
                comments: property.comments || {},
                safetyFlags: property.safetyFlags
            });
        }
    }, [property]);

    const handleChange = (e, section = null) => {
        const { id, value } = e.target;
        if (section) {
            setFormData(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [id]: value
                }
            }));
        } else {
            setFormData(prevState => ({ ...prevState, [id]: value }));
        }
    };

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: checked ? "true" : "false" }));
    };

    const handleSave = async () => {
        const propertyRef = doc(db, 'properties', id);
        try {
            await setDoc(propertyRef, formData, { merge: true });
            setShowModal(true);
            console.log('Document successfully updated!');
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    }

    return (
        <>
            <div className="container py-2">
                <div className="row p-2">
                    <div className="col-lg-12 py-3">
                        <h1><i className="fa fa-search" aria-hidden="true"></i> Record View</h1>
                        <hr />
                        <Link className="navbar-brand float-end mx-2" to="/mapView">
                            <div className="btn btn-primary float-end mx-2">Back to map</div>
                        </Link>
                        <div>
                            {property ? (
                                <h2>ID: <span className="record_id">{id}</span></h2>
                            ) : (
                                <div>ID: {id} was not found</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {property && (
                <div className="container pb-5">
                    <div className="row" style={{ paddingBottom: "50px" }}>
                        <div id="record-view">
                            <nav>
                                <div className="nav nav-tabs flex-column flex-md-row" id="record-view-tab" role="tablist">
                                    <button className="nav-link active" id="nav-record-tab" data-bs-toggle="tab" data-bs-target="#nav-record" type="button" role="tab" aria-controls="nav-record" aria-selected="true">Record View</button>
                                    <button className="nav-link" id="nav-comments-tab" data-bs-toggle="tab" data-bs-target="#nav-comments" type="button" role="tab" aria-controls="nav-comments" aria-selected="false">Comments</button>
                                    <button className="nav-link" id="nav-checklists-tab" data-bs-toggle="tab" data-bs-target="#nav-checklists" type="button" role="tab" aria-controls="nav-checklists" aria-selected="false">Checklists</button>
                                    <button className="nav-link" id="nav-scoring-tab" data-bs-toggle="tab" data-bs-target="#nav-scoring" type="button" role="tab" aria-controls="nav-scoring" aria-selected="false">Scoring & Deficiencies</button>
                                    <button className="nav-link" id="nav-safety-tab" data-bs-toggle="tab" data-bs-target="#nav-safety" type="button" role="tab" aria-controls="nav-safety" aria-selected="false">Safety Flag</button>
                                </div>
                            </nav>
                            <div className="tab-content p-3 border bg-white shadow" id="nav-tabContent">
                                <RecordViewTab data={formData.recordview} onChange={(e) => handleChange(e, 'recordview')} onCheckboxChange={handleCheckboxChange} />
                                <CommentTab data={formData.comments} onChange={(e) => handleChange(e, 'comments')} />
                                <ChecklistTab data={formData} onChange={handleChange} />
                                <ScoringTab data={formData} onChange={handleChange} />
                                <SafetyFlagTab data={formData.safetyFlags} onChange={(e) => handleChange(e, 'safetyFlags')} />
                            </div>
                        </div>
                    </div>

                    <Link className=" float-end mx-2" to="/mapView">
                        <div className="btn btn-outline-primary float-end mx-1">cancel</div>
                    </Link>
                    <button onClick={handleSave} className="btn btn-primary float-end mx-1">Save</button>
                </div>


            )}


            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" style={{ zIndex: "99999" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">New Record Added</h5>
                            </div>
                            <div className="modal-body">
                                <p>This record has been updated</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={closeModal}>close</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )}
        </>
    );
}

export default PropertyDetails;



const RecordViewTab = ({ data, onChange, onCheckboxChange }) => {



    return (
        <>
            {data ? (

                <div className="tab-pane fade active show " id="nav-record" role="tabpanel" aria-labelledby="nav-record-tab">
                    <form className="row g-1 cna-form">
                        <div className="row col-md-5 g-2">
                            <div className="col-md-12">
                                <label htmlFor="parkName" className="form-label">Park Name:</label>
                                <input type="text" className="form-control" id="parkName" defaultValue={data.parkName || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-12 txtArea">
                                <label htmlFor="parkLocation" className="form-label">Location of Park:</label>
                                <textarea name="roleExplanation" id="parkLocation" defaultValue={data.parkLocation || ""} onChange={onChange}></textarea>
                            </div>
                            <div className="col-12">
                                <label htmlFor="inspectedBy" className="form-label">Inspected By:</label>
                                <input type="text" className="form-control" id="inspectedBy" defaultValue={data.inspectedBy || ""} onChange={onChange} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="inspectDate" className="form-label">Inspection Date:</label>
                                <input type="text" className="form-control" id="inspectDate" defaultValue={data.inspectDate || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="wallSurfaceArea" className="form-label">Wall Surface Area:</label>
                                <input type="text" className="form-control" id="wallSurfaceArea" defaultValue={data.wallSurfaceArea || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="maxWallHeight" className="form-label">Maximum Wall Height:</label>
                                <input type="text" className="form-control" id="maxWallHeight" defaultValue={data.maxWallHeight || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="maxSurfaceArea" className="form-label">Maximum Surface Area:</label>
                                <input type="text" className="form-control" id="maxSurfaceArea" defaultValue={data.maxSurfaceArea || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="wallLength" className="form-label">Wall Length:</label>
                                <input type="text" className="form-control" id="wallLength" defaultValue={data.wallLength || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="wallThickness" className="form-label">Wall Thickness:</label>
                                <input type="text" className="form-control" id="wallThickness" defaultValue={data.wallThickness || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="propertyAbove" className="form-label">Property Above:</label>
                                <input type="text" className="form-control" id="propertyAbove" defaultValue={data.propertyAbove || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="propertyBelow" className="form-label">Property Below:</label>
                                <input type="text" className="form-control" id="propertyBelow" defaultValue={data.propertyBelow || ""} onChange={onChange} />
                            </div>
                        </div>

                        <div className="row col-md-7 g-2">
                            <div className="col-md-4">
                                <label htmlFor="propertyNumber" className="form-label">Property #:</label>
                                <input type="text" className="form-control" id="propertyNumber" defaultValue={data.propertyNumber || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="zoneNumber" className="form-label">Zone #:</label>
                                <input type="text" className="form-control" id="zoneNumber" defaultValue={data.zoneNumber || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="wallNumber" className="form-label">Wall #:</label>
                                <input type="text" className="form-control" id="wallNumber" defaultValue={data.wallNumber || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="systemId" className="form-label">System Identifier:</label>
                                <input type="text" className="form-control" id="systemId" defaultValue={data.systemId || ""} onChange={onChange} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="latitude" className="form-label">Latitude:</label>
                                <input type="text" className="form-control" id="latitude" defaultValue={data.latitude || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="longitude" className="form-label">Longitude:</label>
                                <input type="text" className="form-control" id="longitude" defaultValue={data.longitude || ""} onChange={onChange} />
                            </div>


                            <div className="col-md-6">
                                <label htmlFor="borough" className="form-label">Borough:</label>
                                <input type="text" className="form-control" id="borough" defaultValue={data.borough || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="block" className="form-label">Block:</label>
                                <input type="text" className="form-control" id="block" defaultValue={data.block || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="lot" className="form-label">Lot:</label>
                                <input type="text" className="form-control" id="lot" defaultValue={data.lot || ""} onChange={onChange} />
                            </div>


                            <div className="col-md-6">
                                <label htmlFor="wallDirection" className="form-label">Wall Direction:</label>
                                <input type="text" className="form-control" id="wallDirection" defaultValue={data.wallDirection || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="onLotLine" className="form-label">On Perimeter / Lot Line:</label>
                                <input type="text" className="form-control" id="onLotLine" defaultValue={data.onLotLine || ""} onChange={onChange} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="juristiction" className="form-label">Juristiction:</label>
                                <input type="text" className="form-control" id="juristiction" defaultValue={data.juristiction || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="verified" className="form-label">Verified:</label>
                                <input type="text" className="form-control" id="verified" defaultValue={data.verified || ""} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="wallTag" className="form-label">Wall Tag #</label>
                                <input type="text" className="form-control" id="wallTag" defaultValue={data.wallTag || ""} onChange={onChange} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="inputCity" className="form-label">Wall Material Type:</label>
                                <div className="input-group">
                                    <select
                                        className="form-select"
                                        id="wallMaterial"
                                        value={data.wallMaterial}
                                        onChange={onChange}
                                        aria-label="">
                                        <option value="" disabled>Choose...</option>
                                        <option value="Stone">Stone</option>
                                        <option value="Concrete">Concrete</option>
                                        <option value="Masonary">Masonary</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="input-group-text">
                                        <input
                                            className="form-check-input mt-0"
                                            type="checkbox"
                                            id="vener"
                                            checked={data.vener === "true"}
                                            onChange={onCheckboxChange}
                                        />
                                    </div>
                                    <span className="input-group-text">Vener</span>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <label htmlFor="inputCity" className="form-label">Report / Photos Saved in:</label>
                                <input type="text" className="form-control" id="test16" />
                            </div>

                        </div>
                    </form>
                    <br />
                </div>


            ) : (
                <>none</>
            )}
        </>
    )
}

export default RecordViewTab
import React from 'react';
import ChartComponent from './chartComponent';

const NetCostBenefitChart = () => {
    const netCostBenefit = {
        chart: {
            type: 'column',
            height: 700,
            backgroundColor: "transparent"
        },
        credits: {
            enabled: false,
        },
        title: {
            text: 'Project Benefit by Cost/Benefit',
        },

        subtitle: {
            text: '',
        },

        xAxis: {
            categories: [
                'M843-SYNTURF-0043', 'M272-SYNTURF-0018', 'M539-SYNTURF-0012', 'M707-SYNTURF-0024', 'M167-SYNTURF-0025', 'M286-SYNTURF-0045', 'M088-SYNTURF-0019', 'M018-SYNTURF-0002', 'M070-SYNTURF-0003', '310-SYNTURF-0004', 'M583-SYNTURF-0014', 'M144-SYNTURF-0043', 'M147-SYNTURF-0026',
            ]
        },

        yAxis: [{
            className: 'highcharts-color-0',
            title: {
                text: 'Net Benefit'
            },
            labels: {
                formatter: function () {
                    return this.value.toFixed(1);
                }
            }
        }, {
            className: 'highcharts-color-1',
            opposite: true,
            title: {
                text: 'Cost/Benefit'
            },
            tickInterval: 2000000,
            labels: {
                formatter: function () {
                    return '$' + this.value / 1000000 + 'M';
                }
            },
        }],

        plotOptions: {
            column: {
                borderRadius: '10%'
            }
        },

        series: [{
            name: 'Net Benefit',
            data: [1.9, 2.5, 1.1, 0.7, 0.4, 3.1, 0.3, 0.6, 0.2, 0.5, 0.6, 0.2, 0.1],
            color: "#59A7C6",
        }, {
            name: 'Cost/Benefit',
            data: [
                2000000, 500000, 2000000, 3000000, 2000000, 200000, 200000, 300000, 5000000, 500000, 100000, 12000000, 30000000
            ],
            color: "#C6A659",
            yAxis: 1,
            tooltip: {
                valuePrefix: '$'
            }
        }]

    };

    return (
        <>
            <ChartComponent options={netCostBenefit} />
        </>
    );
}

export default NetCostBenefitChart;

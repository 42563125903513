import React, { useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { GoogleMap, MarkerF, InfoWindow } from '@react-google-maps/api';
import useFetchAllProperties from '../../hooks/useFetchAllProperties';
import GoogleMapsContext from '../GoogleMapsContext';
import MapViewList from './mapViewList';

const containerStyle = {
    width: '100%',
    height: '100%'
};

function PropertyList() {
    const { properties } = useFetchAllProperties();
    const [activePark, setActivePark] = useState(null);
    const isGoogleMapsLoaded = useContext(GoogleMapsContext);

    const [navOpen, setNavOpen] = useState(true);
    const sideWidth = "35%";
    const sideNavRef = useRef(null);
    const toggleBtnRef = useRef(null);

    const toggleNav = () => {
        if (navOpen) {
            sideNavRef.current.style.width = "0";
            toggleBtnRef.current.classList.toggle('close');
        } else {
            sideNavRef.current.style.width = sideWidth;
            toggleBtnRef.current.classList.toggle('close');
        }
        setNavOpen(prevNavOpen => !prevNavOpen);
    };

    console.log("p", properties);
    return (
        <>
            <div className="container py-2">
                <div className="row p-2">
                    <div className="col-lg-12 py-3">
                        <h1> <i className="fa fa-map" aria-hidden="true"></i> Search Map View</h1>
                        <hr />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="py-3">
                    <div id="record-map-wrapper" className="map-container d-flex shadow">
                        <div id="map" className="flex-grow-1">
                            {isGoogleMapsLoaded && (

                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{ lat: 40.730610, lng: -73.935242 }}
                                    zoom={10}
                                >

                                    {properties.map((park, index) => (
                                        <MarkerF
                                            key={index}
                                            position={{
                                                lat: parseFloat(park.recordView?.latitude || park.recordview?.latitude),
                                                lng: parseFloat(park.recordView?.longitude || park.recordview?.longitude)
                                            }}
                                            icon={{
                                                url: (require('../../assets/img/parkMapPin.svg')).default,
                                                scaledSize: new window.google.maps.Size(40, 40),
                                                fillColor: "blue"
                                            }}
                                            onClick={() => setActivePark(park)}
                                        />
                                    ))}

                                    {activePark && (
                                        <InfoWindow
                                            position={{
                                                lat: parseFloat(activePark.recordView?.latitude || activePark.recordview?.latitude),
                                                lng: parseFloat(activePark.recordView?.longitude || activePark.recordview?.longitude)
                                            }}
                                            onCloseClick={() => {
                                                setActivePark(null);
                                            }}
                                        >
                                            <div>
                                                <h4>{activePark?.recordView?.parkName || activePark?.recordview?.parkName}</h4>
                                                <p>ID: {activePark.id}</p>
                                                <p>Last Inspection Date: {activePark.recordView?.inspectDate || activePark.recordview?.inspectDate}</p>
                                                <p><Link to={`/record/${activePark.id}`}>View Record</Link></p>
                                            </div>
                                        </InfoWindow>
                                    )}

                                </GoogleMap>


                            )}
                        </div>

                        <div id="mySidenav" className="side-menu" style={{ width: "35%" }} ref={sideNavRef}>
                            <button id="togglebtn" className="mapMenuOpen" ref={toggleBtnRef} onClick={toggleNav}><span></span></button>
                            <div className="sideContent">
                                <div className="sticky-top" ><h4 className="parks-green-light-back text-center py-2">Condition Assesment List</h4><div className="filter-container d-flex py-1">

                                </div></div>
                                <div className="list-group cond-list p-2">
                                    <MapViewList />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </>
    );
}

export default PropertyList;

import { Link } from 'react-router-dom';
import logo from "../assets/img/Parks_logo.svg"

const Header = () => {

    const logout = () => {
        sessionStorage.setItem("isLoggedIn", "false");
        window.location = "/";
    };

    return (
        <div id="header">
            <nav className="navbar navbar-expand-md bg-white shadow-sm py-3 px-2 px-md-0">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" width="55" height="55" />
                        <span className="fs-6 ms-2"> Capital Needs Assesment</span>
                    </Link>
                    <button className="navbar-toggler shadow-none fs-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav w-100 d-flex justify-content-end py-3 py-md-0">
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to="/">Capital Dashboards</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to="/pagenotprovided">CNA Manual</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to="/pagenotprovided">Help</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to="/pagenotprovided">Admin</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to="#" onClick={logout}>Exit</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;

import NetCostBenefitChart from "./highcharts/netCostBenefitChart";
import CumulativeCostChart from "./highcharts/cumulativeCostChart";

const Reports = () => {
    return (
        <>
            <div className="container py-2">
                <div className="row p-2">
                    <div className="col-lg-12 py-3">
                        <h1> <i className="fa fa-area-chart" aria-hidden="true"></i> Visual Reports</h1>
                        <hr />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row pb-4" >
                    <div className="mb-5">
                        <NetCostBenefitChart />
                    </div>
                    <div>
                        <CumulativeCostChart />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reports


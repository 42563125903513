import { useState, useEffect } from 'react';
import db from '../components/firebase';
import { doc, getDoc } from 'firebase/firestore';

const useFetchProperty = (id) => {
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const propertyRef = doc(db, 'properties', id);
                const propertySnap = await getDoc(propertyRef);

                if (propertySnap.exists()) {
                    setProperty(propertySnap.data());
                } else {
                    setError("No such data");
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProperty();
    }, [id]);

    return { property, loading, error };
}

export default useFetchProperty;

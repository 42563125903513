import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginPage({ onLogin }) {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();

        if (username === "demo" && password === "demo") {
            onLogin(); // Call onLogin
            navigate('/'); // Navigate to main page
        } else {
            setErrorMessage("Invalid login credentials.");
        }
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setErrorMessage("");
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrorMessage("");
    }

    return (
        <section className="login-page-wrap logoBack">
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="bg-white shadow rounded parks-green-back">
                                <div className="row">
                                    <div className="form-right col-md-5 ps-0 d-none d-md-block order-1 order-md-2">
                                        <div className="h-100 parks-green-back text-white ps-3 pt-5 rounded-end">
                                            <h1>Capital<br />Needs<br />Assesment</h1>
                                        </div>
                                    </div>
                                    <div className="form-left col-md-7 pe-0 order-2 order-md-1">
                                        <div className="h-100 py-5 px-5">
                                            <div className="form-area">
                                                <div id="park-login" className="form-input">
                                                    <h1 className="d-block d-md-none parks-green-text">Capital Needs Assesment</h1>
                                                    <h2>Login</h2>
                                                    <form onSubmit={handleLogin}>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                style={{ textTransform: "none" }}
                                                                id=""
                                                                name="name"
                                                                value={username}
                                                                onChange={handleUsernameChange}
                                                                required
                                                            />
                                                            <label>User Name</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                id=""
                                                                name="password"
                                                                value={password}
                                                                onChange={handlePasswordChange}
                                                                required
                                                            />
                                                            <label>Password</label>
                                                        </div>
                                                        {errorMessage && <p className="errorText">{errorMessage}</p>}
                                                        <div className="myform-button">
                                                            <button type="submit" className="btn myform-btn">Login</button>
                                                        </div>
                                                    </form>
                                                    <div className="demoText text-center mt-2">For demo username and password is "demo"</div>
                                                    <div className="demoText text-center">All data in the site uses real park names but all other data fabricated</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoginPage;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import db from '../firebase';
import { doc, getDoc } from 'firebase/firestore';


const ChecklistTab = () => {
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [activeTab, setActiveTab] = useState('checklist-01');  // Default active tab

    const handleSelectChange = (e) => {
        setActiveTab(e.target.value);
    };

    useEffect(() => {
        const fetchProperty = async () => {
            const propertyRef = doc(db, 'properties', id);
            const propertySnap = await getDoc(propertyRef);

            if (propertySnap.exists()) {
                setProperty(propertySnap.data());
            } else {
                console.error("No such property!");
            }
        };

        fetchProperty();
    }, [id]);

    return (
        <>
            {property && (
                <div className="tab-pane fade" id="nav-checklists" role="tabpanel" aria-labelledby="nav-checklists-tab">
                    <div className="container">
                        <div className="col-4">
                            <nav>
                                <select className="form-select" onChange={handleSelectChange} value={activeTab}>
                                    <option value="checklist-01">10 - Pre Inspection</option>
                                    <option value="checklist-02">20 - On Arrival</option>
                                    <option value="checklist-03">30 - Accessibility</option>
                                </select>
                            </nav>
                        </div>
                        <div className="tab-content my-4 p-5 border shadow rounded" id="nav-checklistContent">
                            <TabContent id="checklist-01" isActive={activeTab === 'checklist-01'} title={property.checklists.preInspection} />
                            <TabContent id="checklist-02" isActive={activeTab === 'checklist-02'} title={property.checklists.onArival} />
                            <TabContent id="checklist-03" isActive={activeTab === 'checklist-03'} title={property.checklists.accesibility} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const TabContent = ({ id, title, isActive }) => (
    <div className={`tab-pane checklist-pane fade ${isActive ? 'show active' : ''}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`}>
        <h2 className="text-center">{title}</h2>
    </div>
);

export default ChecklistTab;

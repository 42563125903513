import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, doc, setDoc } from 'firebase/firestore';
import db from './firebase';

const AddNewSite = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const form = event.target;

        const getFieldValue = (id) => {
            const element = form.elements[id];
            if (element) {
                return element.value;
            }
            console.warn(`Element with ID "${id}" not found!`);
            return undefined;
        };




        const newProperty = {
            recordview: {
                parkName: getFieldValue('parkName'),
                parkLocation: getFieldValue('parkLocation'),
                inspectedBy: getFieldValue('inspectBy'),
                inspectDate: getFieldValue('inspectDate'),
                wallSurfaceArea: getFieldValue('wallSurfaceArea'),
                maxWallHeight: getFieldValue('maxWallHeight'),
                maxSurfaceArea: getFieldValue('maxSurfaceArea'),
                wallLength: getFieldValue('wallLength'),
                wallThickness: getFieldValue('wallThickness'),
                propertyAbove: getFieldValue('propertyAbove'),
                propertyBelow: getFieldValue('propertyBelow'),
                propertyNumber: getFieldValue('propertyNumber'),
                zoneNumber: getFieldValue('zoneNumber'),
                wallNumber: getFieldValue('wallNumber'),
                systemId: getFieldValue('systemId'),
                latitude: getFieldValue('latitude'),
                longitude: getFieldValue('longitude'),
                borough: getFieldValue('borough'),
                block: getFieldValue('block'),
                lot: getFieldValue('lot'),
                wallDirection: getFieldValue('wallDirection'),
                onLotLine: getFieldValue('onLotLine'),
                juristiction: getFieldValue('juristiction'),
                verified: getFieldValue('verified'),
                wallTag: getFieldValue('wallTag'),
                wallMaterial: getFieldValue('wallMaterial'), // This captures the select dropdown's value
                isVener: form.elements['vener'].checked, // This captures the checkbox value as true or false
                reportPhotosSavedIn: getFieldValue('test16'),
            },
            comments: {
                summary: getFieldValue('summary'),
                noteAnyPreviousRepair: getFieldValue('noteAnyPreviousRepair'),
                fieldSketch: getFieldValue('fieldSketch'),
            },
            checklists: {
                accesibility: "All Checklist information related to Pre Inspection goes here",
                onArival: "All Checklist information related to On Arrival goes here",
                preInspection: "All Checklist information related to Accesibility goes here",
            },
            safetyFlags: {
                conditions: "",
                hazzard: "",
                imgs: [],
                location: "",
                recommendFollowUpAction: "",
                recommendImmediateAction: "",
            },
        };



        // Generate a unique six-digit ID
        const uniqueId = Math.floor(100000 + Math.random() * 900000).toString();

        try {
            await setDoc(doc(collection(db, 'properties'), uniqueId), newProperty);
            console.log("Document written with ID: ", uniqueId);
            setModalContent(`Record added successfully with ID: ${uniqueId}`);
            setShowModal(true);
            setIsSubmitting(true);
        } catch (error) {
            console.error('Error adding property: ', error);
            setModalContent('Error adding property. Please try again later.');
            setShowModal(true);
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="container" style={{ paddingBottom: "100px" }}>

                <div className="row pb-4">
                    <div className="container py-2">
                        <div className="row p-2">
                            <div className="col-lg-12 py-3">
                                <h1> <i className="fa fa-plus-square" aria-hidden="true"></i> Add New Site</h1>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <nav>
                        <div className="nav nav-tabs flex-column flex-md-row" id="record-view-tab" role="tablist">
                            <button className="nav-link active" id="nav-record-tab" data-bs-toggle="tab" data-bs-target="#nav-record" type="button" role="tab" aria-controls="nav-record" aria-selected="true">Record View</button>
                            <button className="nav-link" id="nav-comments-tab" data-bs-toggle="tab" data-bs-target="#nav-comments" type="button" role="tab" aria-controls="nav-comments" aria-selected="false">Comments</button>
                        </div>
                    </nav>
                    <form className="cna-form" id="addNewRecordFrom" onSubmit={handleSubmit}>
                        <div className="tab-content p-3 border bg-white shadow" id="nav-tabContent">

                            <div className="tab-pane fade active show " id="nav-record" role="tabpanel" aria-labelledby="nav-record-tab">

                                <div className="row g-1 cna-form">
                                    <div className="row col-md-5 g-2">
                                        <div className="col-md-12">
                                            <label htmlFor="parkName" className="form-label">Park Name:</label>
                                            <input type="text" className="form-control" id="parkName" />
                                        </div>
                                        <div className="col-md-12 txtArea">
                                            <label htmlFor="parkLocation" className="form-label">Location of Park:</label>
                                            <textarea name="roleExplanation" id="parkLocation" ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="inspectBy" className="form-label">Inspected By:</label>
                                            <input type="text" className="form-control" id="inspectBy" />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inspectDate" className="form-label">Inspection Date:</label>
                                            <input type="text" className="form-control" id="inspectDate" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="wallSurfaceArea" className="form-label">Wall Surface Area:</label>
                                            <input type="text" className="form-control" id="wallSurfaceArea" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="maxWallHeight" className="form-label">Maximum Wall Height:</label>
                                            <input type="text" className="form-control" id="maxWallHeight" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="maxSurfaceArea" className="form-label">Maximum Surface Area:</label>
                                            <input type="text" className="form-control" id="maxSurfaceArea" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="wallLength" className="form-label">Wall Length:</label>
                                            <input type="text" className="form-control" id="wallLength" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="wallThickness" className="form-label">Wall Thickness:</label>
                                            <input type="text" className="form-control" id="wallThickness" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="propertyAbove" className="form-label">Property Above:</label>
                                            <input type="text" className="form-control" id="propertyAbove" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="propertyBelow" className="form-label">Property Below:</label>
                                            <input type="text" className="form-control" id="propertyBelow" />
                                        </div>
                                    </div>

                                    <div className="row col-md-7 g-2">
                                        <div className="col-md-4">
                                            <label htmlFor="propertyNumber" className="form-label">Property #:</label>
                                            <input type="text" className="form-control" id="propertyNumber" />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="zoneNumber" className="form-label">Zone #:</label>
                                            <input type="text" className="form-control" id="zoneNumber" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="wallNumber" className="form-label">Wall #:</label>
                                            <input type="text" className="form-control" id="wallNumber" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="systemId" className="form-label">System Identifier:</label>
                                            <input type="text" className="form-control" id="systemId" />
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="latitude" className="form-label">Latitude:</label>
                                            <input type="text" className="form-control" id="latitude" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="longitude" className="form-label">Longitude:</label>
                                            <input type="text" className="form-control" id="longitude" />
                                        </div>


                                        <div className="col-md-6">
                                            <label htmlFor="borough" className="form-label">Borough:</label>
                                            <input type="text" className="form-control" id="borough" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="block" className="form-label">Block:</label>
                                            <input type="text" className="form-control" id="block" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="lot" className="form-label">Lot:</label>
                                            <input type="text" className="form-control" id="lot" />
                                        </div>


                                        <div className="col-md-6">
                                            <label htmlFor="wallDirection" className="form-label">Wall Direction:</label>
                                            <input type="text" className="form-control" id="wallDirection" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="onLotLine" className="form-label">On Perimeter / Lot Line:</label>
                                            <input type="text" className="form-control" id="onLotLine" />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="juristiction" className="form-label">Juristiction:</label>
                                            <input type="text" className="form-control" id="juristiction" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="verified" className="form-label">Verified:</label>
                                            <input type="text" className="form-control" id="verified" />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="wallTag" className="form-label">Wall Tag #</label>
                                            <input type="text" className="form-control" id="wallTag" />
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="inputCity" className="form-label">Wall Material Type:</label>
                                            <div className="input-group">
                                                <select
                                                    className="form-select"
                                                    id="wallMaterial"
                                                    aria-label="">
                                                    <option value="" disabled>Choose...</option>
                                                    <option value="Stone">Stone</option>
                                                    <option value="Concrete">Concrete</option>
                                                    <option value="Masonary">Masonary</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <div className="input-group-text">
                                                    <input
                                                        className="form-check-input mt-0"
                                                        type="checkbox"
                                                        id="vener"
                                                    />
                                                </div>
                                                <span className="input-group-text">Vener</span>
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <label htmlFor="inputCity" className="form-label">Report / Photos Saved in:</label>
                                            <input type="text" className="form-control" id="test16" />
                                        </div>

                                    </div>
                                </div>
                                <br />
                            </div>

                            <div className="tab-pane fade" id="nav-comments" role="tabpanel" aria-labelledby="nav-comments-tab">
                                <div className="row gx-3 gy-2 align-items-center pb-4 comments-form">
                                    <div className="col-sm-12">
                                        <label className="visually-hidden" htmlFor="summary">Summary</label>
                                        <div className="input-group">
                                            <div className="input-group-text">Summary</div>
                                            <textarea type="text" className="form-control auto-resize" id="summary" placeholder="" ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="visually-hidden" htmlFor="noteAnyPreviousRepair">Note Any Previous Repairs</label>
                                        <div className="input-group">
                                            <div className="input-group-text">Note Any Previous Repairs</div>
                                            <textarea type="text" className="form-control auto-resize" id="noteAnyPreviousRepair" placeholder=""></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="visually-hidden" htmlFor="fieldSketch">Field Sketch</label>
                                        <div className="input-group">
                                            <div className="input-group-text">Field Sketch</div>
                                            <textarea type="text" className="form-control auto-resize" id="fieldSketch" placeholder=""></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </form>
                </div>
                <button className="btn btn-primary float-end mt-3" type="submit" form="addNewRecordFrom" disabled={isSubmitting}>Add Property</button>
            </div>


            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" style={{ zIndex: "99999" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">New Record Added</h5>
                            </div>
                            <div className="modal-body">
                                <p>{modalContent}</p>
                            </div>
                            <div className="modal-footer">
                                <Link to={`/record/${modalContent.split(' ').slice(-1)}`} className="btn btn-primary">View Record</Link>
                                <Link to="/dashboard" className="btn btn-primary" >Back to Dashboard</Link>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )}


        </>
    )
}

export default AddNewSite
import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import useFetchAllProperties from '../hooks/useFetchAllProperties';



const SearchRecord = () => {
    const { properties } = useFetchAllProperties();
    const navigate = useNavigate();

    const idColumn = {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    };

    const orderedKeys = ['parkName', 'parkLocation', 'borough', 'inspectedBy', 'inspectDate', 'lot', 'propertyNumber', 'zoneNumber']
    const camelCaseToReadable = (str) => {
        // Insert a space before all caps and then capitalize the first letter
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (firstLetter) => firstLetter.toUpperCase());
    };

    const recordViewColumns = properties && properties.length > 0 && properties[0].recordview
        ? orderedKeys.map(key => {
            if (properties[0].recordview[key] !== undefined) {
                return {
                    title: camelCaseToReadable(key),
                    dataIndex: ['recordview', key],
                    key: key,
                };
            } else {
                return null;
            }
        }).filter(col => col !== null)
        : [];

    const columns = [idColumn, ...recordViewColumns];

    return (
        <>

            <div className="container py-2">
                <div className="row p-2">
                    <div className="col-lg-12 pt-3">
                        <h1> <i className="fa fa-search" aria-hidden="true"></i> Record Search</h1>
                        <hr />
                    </div>
                </div>
            </div>

            <div className="container-fluid pb-5">
                <div className="row p-2">
                    <div className="col-lg-12 px-3 recTableWrap">
                        <Table
                            dataSource={properties}
                            columns={columns}
                            rowKey="id"
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        navigate(`/record/${record.id}`);  // Step 4: Use history.push to navigate
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

        </>
    )
};

export default SearchRecord
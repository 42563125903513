import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header'


function MainPage() {
    return (
        <div className="main-page logoBack">

            <Header />

            <Outlet />

        </div>
    );
}

export default MainPage;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";

import GoogleMapsContext from './components/GoogleMapsContext';
import { LoadScript } from '@react-google-maps/api';

import LoginPage from './components/login';
import MainPage from './components/main';
import FourOfour from './components/404';
import PropertyList from './components/record-view/mapView';
import PropertyDetails from './components/record-view/recordDetails';
import Dashboard from './components/dashboard';
import SearchRecord from './components/searchRecord';
import AddNewSite from './components/addNewSite';
import Reports from './components/reports';
import PageNotProvided from './components/pageNotProvided';

import data from './data.json'; // Assuming data.json is in the same directory as App.js


import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('isLoggedIn') === 'true');

  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem('isLoggedIn', 'true');
  }

  // useEffect to listen for changes in sessionStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(sessionStorage.getItem('isLoggedIn') === 'true');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyB_8QFgbfMx71h_yQJBeLe-mIi5Henpuc8">
      <GoogleMapsContext.Provider value={true}>
        <Router>
          <Routes>
            <Route path="/login" element={!isLoggedIn ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/" replace />} />
            <Route path="/" element={isLoggedIn ? <MainPage /> : <Navigate to="/login" replace />}>
              <Route path="main" element={<Navigate to="/" replace />} />
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Navigate to="/" replace />} />
              <Route path="mapview" element={<PropertyList properties={data.properties} />} />
              <Route path="record/:id" element={<PropertyDetails />} />
              <Route path="searchrecord" element={<SearchRecord />} />
              <Route path="addnewsite" element={<AddNewSite />} />
              <Route path="reports" element={<Reports />} />
              <Route path="pagenotprovided" element={<PageNotProvided />} />
            </Route>
            <Route path="*" element={<FourOfour />} />
          </Routes>
        </Router>
      </GoogleMapsContext.Provider>
    </LoadScript>


  );
}

export default App;
